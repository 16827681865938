export const skills = [
    {
        id: 1,
        title: 'HTML5',
        progress: '95%',
    },
    {
        id: 2,
        title: 'CSS3',
        progress: '90%',
    },
    {
        id: 3,
        title: 'javascript',
        progress: '85%',  
    },
    {
        id: 4,
        title: 'JQuery',
        progress: '75%',
    },
    {
        id: 5,
        title: 'Bootstrap',
        progress: '65%',
    },
    {
        id: 6,
        title: 'PHP',
        progress: '60%',
    },

];

export const education = [

    {
        id: 1, 
        duration: '2015 - 2016',
        title: 'science',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 2,
        duration: '2016 - 2017',
        title: 'software engineering',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 3,
        duration: '2017 - 2018',
        title: 'computer science',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
 
];


export const experience = [

    {
        id: 1,
        duration: '2018 - 2019',
        title: 'frontend development',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 2,
        duration: '2019 - 2020',
        title: 'backend development',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 3,
        duration: '2020 - 2021',
        title: 'php development',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
 
];