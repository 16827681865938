import { images } from '.';

const blogs = [

    {
        id: 1,
        Image: images.blog1,
        category: 'development',
        duration: 3,
        title: 'Web design trends',
        link: '#',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '5th',
        month: 'feb',
        year: '2022',
    },
    {
        id: 2,
        Image: images.blog2,
        category: 'design',
        duration: 4,
        title: 'How I Designed My Website',
        link: '#',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '15th',
        month: 'mar',
        year: '2022',
    },
    {
        id: 3,
        Image: images.blog3,
        category: 'photography',
        duration: 7,
        title: 'My Resource List',
        link: '#',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '1st',
        month: 'june',
        year: '2022',
    },
    {
        id: 4,
        Image: images.blog4,
        category: 'illustration',
        duration: 5,
        title: 'Program Tutorials',
        link: '#',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '29th',
        month: 'june',
        year: '2022',
    },
];

export default blogs;