import React from 'react';
import './Button.css'; 

import { Link } from 'react-router-dom';
 


const Button = ({title, link=''}) => {
  return (
    <Link to={`${link}`} className="btn">{title}</Link>  
  ) 
}

export default Button 