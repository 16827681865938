import React, {useState, useEffect} from 'react';
import './Portfolio.css';
 
import { PortfolioList, PortfolioItem, Heading } from '../../components';
import { portfolioContent } from '../../constants';

import { LightBox } from 'react-lightbox-pack'; 
import "react-lightbox-pack/dist/index.css"; 

   
  
const Portfolio = () => {
 
  // State
  const [tag, setTag] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);

	const [toggle, setToggle] = useState(false);
	const [sIndex, setSIndex] = useState(0);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

  const popupImage = (index) => {
    lightBoxHandler(true, index);
  } 

  useEffect( () => {
    tag == 'all' ? setFilteredImages(portfolioContent) : setFilteredImages(portfolioContent.filter((image) => image.tag == tag))
  }, [tag])

  const list = [
    {
      id: "all", 
      title: "all",
    },
    {
      id: "web",
      title: "web development",
    },
    {
      id: "graphic",
      title: "graphic design",
    },
    {
      id: "photography",
      title: "photography",
    }, 
    {
      id: "illustration", 
      title: "illustration",
    }, 
  ];
 
  return (
    <section className='portfolio'>

      <Heading title={'our projects'} span={'some of my works'} />

      <ul className='controls'>
        { 
          list.map((item) => {
            return(
              <PortfolioList
              id={item.id} 
              title={item.title}
              setSelected={setTag}
              active={tag == item.id ? true: false }
              />
            ) 
          })
        }
      </ul>

      <div className='box-container'>
        {
          filteredImages.map((item, index) => {
            return(
              <PortfolioItem
              id={item.id}
              image={item.image}
              title={item.title}
              category={item.category}
              link={item.link}
              index={index}
              popup={popupImage}
              /> 

            )
          })
        }

        <LightBox
          state={toggle}
          event={lightBoxHandler}
          data={portfolioContent}
          imageWidth="60vw"
          imageHeight="70vh"
          thumbnailHeight={50}
          thumbnailWidth={50}
          setImageIndex={setSIndex}
          imageIndex={sIndex}
        />
          
      </div>

    </section>

  )
}

export default Portfolio;