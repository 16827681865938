import React from 'react';
import './PortfolioList.css';


 
const PortfolioList = ({id, title, active, setSelected}) => {
  return ( 
    <li 
        key={id}
        className={active ? "button active" : "button"}
        onClick={() => setSelected(id)}>
        {title}
        <span></span>
        <span></span> 
        <span></span>
        <span></span>
    </li>
  )
}
 
export default PortfolioList;