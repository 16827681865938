import React from 'react';
import './About.css';

import { Heading, Button } from '../../components';
import { education, experience, skills } from '../../constants';

import { FaGraduationCap, FaBriefcase } from 'react-icons/fa';
import CountUp from 'react-countup';



const About = () => {  

  const size = 6;
  const skill = skills.slice(0, size);
  const skill1 = skill.slice(0, 3);
  const skill2 = skill.slice(3, size);

  return (
    <section className='about' id='about'>

      <Heading title={'about me'} span={'know about me'} />

      {/*---------- Personal Information ----------*/}
      <div className='box-container'>

        <h5>personal info</h5>

        <div className='text'>

          <h2>My name is serena.</h2> 
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
              Recusandae aut adipisci necessitatibus veniam, provident 
              vero architecto praesentium, non sequi libero corrupti molestias. 
              Architecto voluptate eligendi maiores, error magni accusamus officia.</p>
          <div className='about-info'>
            <h4>age: <span>24</span></h4>
            <h4>gender: <span>female</span></h4>
            <h4>language: <span>urdu, english</span></h4>
            <h4>work: <span>web developer</span></h4>
            <h4>freelance: <span>available</span></h4>
          </div>
          <Button title={'download CV'} />

        </div> 

        <div class="counting"> 

          <div class="box">
            <CountUp class="count" end={6} duration={3} suffix={'+'} />
            <h3>years of experience</h3>
          </div>
          
          <div class="box">
              <CountUp class="count" end={200} duration={3} suffix={'+'} />
              <h3>projects delivered</h3>
          </div>

          <div class="box">
          <CountUp class="count" end={100} duration={3} suffix={'+'} />
              <h3>happy clients</h3>
          </div>
                      
          <div class="box">
          <CountUp class="count" end={25} duration={3} suffix={'+'} />
              <h3>awards won</h3>
          </div>
      
        </div>

      </div>

      {/*---------- Skills ----------*/}
      <div className='skill-container'>
        <h5>my skills</h5>

        <div className='skills'> 

          <div className='skill-item item1'>            
          {
              skill1.map((skill) => {
                return(
                  <div className='box' key={skill.id}>
                    <h3>{skill.title} <span>{skill.progress}</span></h3>
                    <div className='progress-bar'> <span style={{width:skill.progress}}></span> </div>
                  </div>
                )
              })
            } 
          </div>

          <div class="skill-item item2">
          {
              skill2.map((skill) => {
                return(
                  <div className='box' key={skill.id}>
                    <h3>{skill.title} <span>{skill.progress}</span></h3>
                    <div className='progress-bar'> <span style={{width:skill.progress}}></span> </div>
                  </div>
                )
              })
            }
          </div>

        </div>

      </div>

      {/*---------- Resume ----------*/}
      <div className='resume'>
        <h5>my resume</h5>

        <div className='resume-container'>
          <div className='education'>

            <h6><span>education</span></h6>
            {
              education.map((item) => {
                return(
                  <div class="education-item" key={item.id}>
                    <FaGraduationCap className='icon' />
                    <div class="content">
                        <span>{item.duration}</span>
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className='experience'>

            <h6><span>experience</span></h6>

            {
              experience.map((item) => {
                return(
                  <div class="experience-item" key={item.id}>
                    <FaBriefcase className='icon' />
                    <div class="content">
                        <span>{item.duration}</span>
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

    </section>
  )
}

export default About;