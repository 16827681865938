import React from 'react';
import { Sidebar } from './components';
import { About, Blogs, Contact, Home, Portfolio, Projects, Services, Testimonials } from './sections';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


const App = () => {
  return (
    <div className="App">
      <Sidebar />

      <Routes>
        
        {/*Home*/}
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Portfolio' element={<Portfolio />} />
        <Route path='/Testimonials' element={<Testimonials />} />
        <Route path='/Blogs' element={<Blogs />} />
        <Route path='/Contact' element={<Contact />} />

      </Routes>
      


    {/*About
      <Routes>
        <Route path='/' element={<Home />} />


        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/faqs' element={<Faqs />} />
        <Route path='/team' element={<TeamPage />} />
        <Route path='/testimonials' element={<TestimonialsPage />} />

        <Route path='/gallery' element={<Gallery />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/order-list' element={<OrderList />} />
        <Route path='/order-details' element={<OrderDetails />} />
        
        <Route path='/blog-grid' element={<BlogGrid />} />
        <Route path='/blog-list' element={<BlogList />} /> 
        <Route path='/blogs/:id' element={<BlogDetails  />} />
        <Route path='/blog-details' element={<BlogDetails />} />

        <Route path='/shop-grid' element={<ShopGrid />} /> 
        <Route path='/shop-list' element={<ShopList />} />
        <Route path='/shop/:id' element={<ProductDetails />} />
        <Route path='/product-details' element={<ProductDetails />} />

        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/change-pwd' element={<ChangePwd />} />
        <Route path='/forgot-pwd' element={<ForgotPwd />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/edit-profile' element={<EditProfile />} />
        <Route path='/edit-address' element={<EditAddress />} />

        <Route path='/contact-us' element={<ContactUs />} />

      </Routes>
      <ScrollTop />
      */}

    </div>
  )
}

export default App;