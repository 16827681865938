import { images } from '.'; 
 
const portfolioContent = [
    {
        id: 1,
        image: images.portfolio1,
        title: 'Gaming App',
        category: 'web development',
        tag: 'web',
        link: '#'
    },
    {
        id: 2,
        image: images.portfolio2,
        title: 'Healthcare App',
        category: 'web development',
        tag: 'web',
        link: '#'
    },
    {
        id: 3,
        image: images.portfolio3,
        title: 'E-Commerce',
        category: 'web development',
        tag: 'web',
        link: '#',
    },
    {
        id: 4,
        image: images.portfolio4,
        title: 'Postcards',
        category: 'graphic design',
        tag: 'graphic',
        link: '#'
    },
    {
        id: 5,
        image: images.portfolio5,
        title: 'Posters',
        category: 'graphic design',
        tag: 'graphic',
        link: '#'
    },
    {
        id: 6,
        image: images.portfolio6,
        title: 'Landscape',
        category: 'photography',
        tag: 'photography',
        link: '#'
    },
    {
        id: 7,
        image: images.portfolio7,
        title: 'Garden',
        category: 'photography',
        tag: 'photography',
        link: '#'
    },
    {
        id: 8,
        image: images.portfolio8,
        title: 'plant',
        category: 'illustration',
        tag: 'illustration',
        link: '#'
    },
    {
        id: 9,
        image: images.portfolio9,
        title: 'Floral',
        category: 'illustration',
        tag: 'illustration',
        link: '#'
    },
];

export default portfolioContent;

